<template>
  <div class="lecturer-time-container">
    <!-- navbar -->
    <qb-navbar title="案例展示"></qb-navbar>
    <!-- title -->
    <qb-title
      title="泉邦讲师团队"
      subtitle="Quanbang Group lecturer Team"
    ></qb-title>

    <van-image
      lazy-load
      class="image"
      v-for="item in 9"
      :key="item"
      :src="require(`@/assets/images/lecturer/lecturer-${item}.png`)"
    >
    </van-image>
  </div>
</template>

<script>
export default {
  name: 'Lecturer'
}
</script>

<style lang="less" scoped>
.image {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
